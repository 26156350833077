/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'MontserratAlt-regular';
    src: url('/assets/font/MontserratAlternates-Regular.ttf');
  }
  @font-face {
    font-family: 'MontserratAlt-bold';
    src: url('/assets/font/MontserratAlternates-Bold.ttf');
  }
  @font-face {
    font-family: 'MontserratAlt-sbold';
    src: url('/assets/font/MontserratAlternates-SemiBold.ttf');
  }
  @font-face {
    font-family: 'MontserratAlt-medium';
    src: url('/assets/font/MontserratAlternates-Medium.ttf');
  }
  @font-face {
    font-family: 'Montserrat-l';
    src: url('/assets/font/Montserrat-Light.ttf');
  }
  @font-face {
    font-family: 'Montserrat-sbold';
    src: url('/assets/font/Montserrat-SemiBold.ttf');
  }
  @font-face {
    font-family: 'Montserrat-reg';
    src: url('/assets/font/Montserrat-Regular.ttf');
  }
html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'MontserratAlt-regular';
}
.mat-drawer-container{min-height: 50rem;}
h1,button,label{font-family: 'MontserratAlt-bold'!important;}
a{font-family: 'MontserratAlt-sbold';font-size: 21px;}
a:hover{color:#675A76}
p,li,input{font-family: 'Montserrat-reg';}
h2{font-family: 'Montserrat-l';}
h6{font-family: 'Montserrat-sbold';font-size: 22px;color: #675A76;}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.content-dash {
  background: white;
  padding: 30px;
  border-radius: 30px;
}
//.cdk-overlay-container {
//  display: grid;
//  height: 100vh;
//  justify-content: center;
//}
 .cdk-overlay-container {
  margin: auto !important;
   display: flex;
  justify-content: center;
  align-items: center;
   height: auto;
  position: absolute;
  width: 100%;
  top: 50%;
}
.cdk-global-overlay-wrapper {
  position: absolute;
  display: flex;
  align-content: center;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute !important;
  width: fit-content!important;
  height: fit-content!important;
  display: contents;
}
.mat-mdc-select-panel {
  display: block;
  position: absolute!important;
  left: 53%;
}

.relative-position {
  position: initial !important;
}
.custom-dialog .cdk-overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}
button.close {
  float: right;
}
.form_dialog {
  padding: 30px;
}
.form_dialog h1{
  font-size: 22px;
}
.form_dialog form{
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px;
}
.form_dialog form input, .form_dialog form select{
  height: 40px !important;
    width: 88%;
    border: none;
    border-bottom: 2px solid;
}
.form_dialog button {
  background-color: black;
  padding: 10px;
  color: white;
  border: none;
  position: absolute;
  margin: auto;
  right: 25%;
  top: 70%;
  width: 50%;
}
@media screen and (max-width: 768px) {
  .menu {
    display: none; /* Oculta el menú en dispositivos móviles */
  }
  .form_dialog form{
    grid-template-columns: 100%;
  }
  .form_dialog button{
    position: relative;
    padding-bottom: 20px;
    top: 0;
  }
  span.estatus{
    display: inline-flex;
  }
  .mat-mdc-dialog-container .mdc-dialog__container{
    height: fit-content;
  }

  .hamburger-menu {
    display: block; /* Muestra el menú hamburguesa en dispositivos móviles */
  }
  .column{
    flex-direction: column;
    align-items: flex-start!important;
}
button.disable{
    width: 100%!important;
}
}
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
.carru .left, .carru .right {
  display: none !important;
}
.custom-dialog-container {
  background-color: transparent !important; /* Fondo del dialogo */
  z-index: 99999;
}

.custom-dialog-container .mat-dialog-container {
  background-color: white; 
  position: relative;
  z-index: 10000; 
}

.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 9999; 
}.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.custom-dialog-zindex {
  z-index: 99999 !important; 
}